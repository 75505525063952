<!-- 乘车记录 -->

<template>
  <div id="byCar">
    <button-router></button-router>

    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
#byCar {
  height: 100%;
}
</style>
